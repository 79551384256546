<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
      <div class="card card-body">
        <p>
          <span>نطام اوكتو واتس هو خدمة تابعة للواتس الذكي تفيد الشركات واصحاب الاعمال<br> ومن المميزات:</span>
          <ul>
            <li>
              بديل للواتساب ويب مع خصائص اكثر للدعم الفني.
            </li>
            <li>
              فتح الواتساب من اكثر من جهاز ومن اي عدد اجهزة من المتصفح مباشرة.
            </li>
            <li>
              يصلح للدعم الفني للشركات والمؤسسات.
            </li>
            <li>
              اضافة مستخدمين وتحديد صلاحيات لهم على المحادثات. (مثل اخفاء محادثات وتخصيصها وغيرها)
            </li>
            <li>
              انشاء تصنيفات وتحديد موظفين للتعامل معها (مثل: من لهم مشاكل فقط).
            </li>
            <li>
              تخصيص محادثات وغلقها لباقي المستخدمين.
            </li>
            <li>
              ارسال واستقبال الصور والملفات والتسجلات الصوتية.
            </li>
            <li>
              والكثير من المميزات...
            </li>
          </ul>
        </p>
      </div>
      <div class="card card-body text-center" v-if="user.plan >= 4">
        <h5 class="col-12 col-lg-8 c text-left">
          <button 
            v-if="!loading"
            @click="go()"
            class="btn btn-success btn-block"
          >
            <i class="fa fa-external-link"></i>
            الذهاب للنظام الان
          </button>
          <button 
            v-if="loading"
            class="btn btn-outline-success disabled btn-block" disabled
          >
            جاري التحميل...
          </button>
        </h5>
      </div>
      <div class="card card-body text-center" v-if="user.plan < 4">
        <h5 class="col-12 c text-danger">
          يجب الاشتراك في الخطة الالماسية لتتمكن من استخدام هذه الخدمة.
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  methods: {
    go() {
      var g = this;
      g.loading = true;
      $.post(api + "/user/subscribe/octo", {
        jwt: this.user.jwt,
      })
        .then(function (r) {
          g.loading = false;
          if(r.status != 100){
            alert(r?.response)
          }else{
            window.open(octo + '/autologin/' + r.response)
          }
        })
        .catch(function (e) {
          g.loading = false;
          console.log(e)
          alert("حدث خطأ اثناء الاتصال بالخادم");
        });
    },
  },
};
</script>

<style>
</style>